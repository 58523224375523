import React from 'react';
import googleLogo from '../../images/register/google.svg';
import UntitledButton from '../ui/UntitledButton';
import handleVendorSignUp from './handleVendorSignUp';
import styles from './VendorButton.module.scss';

interface VendorButtonProps {
  email?: string | null;
  platform: 'google';
  source?: string;
  referral?: string;
  redirectPath?: string;
}

const PLATFORMS: {
  [key: string]: {
    name: string;
    icon: string;
  };
} = {
  google: { name: 'Google', icon: googleLogo },
};

const VendorButton: React.SFC<VendorButtonProps> = ({
  email,
  platform,
  referral,
  source,
  redirectPath,
}) => {
  const { name, icon } = PLATFORMS[platform];

  const handleSignUp: () => void = () => {
    handleVendorSignUp(platform, {
      email,
      source,
      referral,
      force: true,
      redirectPath,
    });
  };

  return (
    <UntitledButton variant="secondary" onClick={handleSignUp} block>
      <div className={styles.inner}>
        <img src={icon} alt={`${name} logo`} />
        Sign up with {name}
      </div>
    </UntitledButton>
  );
};

export default VendorButton;
