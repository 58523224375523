import React from 'react';
import styles from './BlogSubscriptionInsert.module.scss';
import CustomerIOForm from './CustomerIOForm';

const BlogSubscriptionInsert: React.SFC = () => (
  <div className={styles.container}>
    <div className={styles.inner}>
      <div className={styles.text}>
        <div className={styles.title}>Subscribe</div>
        Get notified when we publish a new article
      </div>
      <CustomerIOForm />
    </div>
  </div>
);

export default BlogSubscriptionInsert;
