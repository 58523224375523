import { useCallback, useEffect, useRef, useState } from 'react';
import LoggedInTeams from './LoggedInTeams';

interface UseLoggedInTeamsOptions {
  shouldRefreshFirst?: boolean;
}

const useLoggedInTeams = ({
  shouldRefreshFirst,
}: UseLoggedInTeamsOptions = {}) => {
  const lastRefreshedHostRef = useRef<string | null>(null);
  const [loggedInTeams, setLoggedInTeams] = useState(() =>
    LoggedInTeams.getCached(),
  );

  const loadTeams = useCallback(() => {
    LoggedInTeams.getAll().then(setLoggedInTeams);
  }, []);

  useEffect(() => {
    loadTeams();
  }, [loadTeams]);

  const firstTeam = loggedInTeams[0];

  useEffect(() => {
    if (shouldRefreshFirst) {
      if (firstTeam && lastRefreshedHostRef.current !== firstTeam.host) {
        lastRefreshedHostRef.current = firstTeam.host;
        LoggedInTeams.getAndCacheOrganizationByHost(firstTeam.host).then(() =>
          loadTeams(),
        );
      }
    } else {
      lastRefreshedHostRef.current = null;
    }
  }, [shouldRefreshFirst, firstTeam, loadTeams]);

  return loggedInTeams;
};

export default useLoggedInTeams;
