import { ClientError } from 'graphql-request';
import API from './api';
import { loggedInHostsCookie } from './Cookies';

const ORGANIZATIONS_LOCAL_STORAGE_KEY = 'organizations';

type OrganizationData = {
  name: string;
  logo?: string;
};

export type LoggedInTeam = { host: string } & OrganizationData;

function getOrganizations(): Record<string, OrganizationData> {
  if (typeof localStorage === 'undefined') return {};
  return JSON.parse(
    localStorage.getItem(ORGANIZATIONS_LOCAL_STORAGE_KEY) || '{}',
  );
}

function saveOrganizations(organizations: Record<string, OrganizationData>) {
  if (typeof localStorage === 'undefined') return;
  localStorage.setItem(
    ORGANIZATIONS_LOCAL_STORAGE_KEY,
    JSON.stringify(organizations),
  );
}

function getHosts(): string[] {
  const hosts = loggedInHostsCookie.get();
  return Array.isArray(hosts) ? hosts : [];
}

function saveHosts(hosts: string[]) {
  loggedInHostsCookie.set(hosts);
}

function removeOrganizationByHost(host: string) {
  const organizations = getOrganizations();
  delete organizations[host];
  saveOrganizations(organizations);

  const hosts = getHosts();
  saveHosts(hosts.filter((h) => h !== host));
}

function getOrganizationByHost(host: string) {
  return API.request<{
    organization: {
      id: string;
      name: string;
      logo?: {
        thumb: string;
      };
    };
  }>(
    `query organization($host: String!) {
      organization(host: $host) {
        id
        name
        logo {
          thumb
        }
      }
    }`,
    { host },
  );
}

async function getAndCacheOrganizationByHost(
  host: string,
): Promise<LoggedInTeam | null> {
  try {
    const { organization } = await getOrganizationByHost(host);
    const data: OrganizationData = {
      logo: organization.logo?.thumb,
      name: organization.name,
    };

    const organizations = getOrganizations();
    saveOrganizations({
      ...organizations,
      [host]: data,
    });

    return {
      host,
      name: organization.name,
      logo: organization.logo?.thumb,
    };
  } catch (error) {
    if (error && typeof error === 'object' && 'response' in error) {
      const clientError = error as ClientError;
      const message = clientError.response.errors?.[0].message;

      if (message === 'NOT_FOUND') {
        removeOrganizationByHost(host);
      }
    }
    return null;
  }
}

function getCached(): LoggedInTeam[] {
  const organizations = getOrganizations();
  const hosts = getHosts();

  return hosts
    .filter((host) => organizations[host] != null)
    .map((host) => ({
      host,
      ...organizations[host],
    }));
}

async function getAll(): Promise<LoggedInTeam[]> {
  const organizations = getOrganizations();
  const hosts = getHosts();
  const teams = await Promise.all(
    hosts.map(async (host) => {
      const cachedOrg = organizations[host];
      if (cachedOrg) {
        return {
          ...cachedOrg,
          host,
        };
      }

      // team hasn't been cached yet, so we cache it initially
      return getAndCacheOrganizationByHost(host);
    }),
  );

  return teams.filter((team): team is LoggedInTeam => team != null);
}

const LoggedInTeams = {
  getAll,
  getCached,
  getHosts,
  getAndCacheOrganizationByHost,
};

export default LoggedInTeams;
