import classNames from 'classnames';
import React from 'react';
import CustomerIOForm from './CustomerIOForm';
import styles from './FloatingSubscribe.module.scss';

interface FloatingSubscribeProps {
  onSubscribed: () => void;
  visible: boolean;
}

const FloatingSubscribe: React.SFC<FloatingSubscribeProps> = ({
  onSubscribed,
  visible,
}) => (
  <div
    className={classNames(styles.container, {
      [styles.visible]: visible,
    })}
  >
    <div className={styles.inner}>
      <div className={styles.text}>
        <strong>Enjoying the post?</strong> Get notified when we publish a new
        article.
      </div>
      <CustomerIOForm onSubscribed={onSubscribed} />
    </div>
  </div>
);

export default FloatingSubscribe;
