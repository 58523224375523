import { Link } from 'gatsby';
import React from 'react';
import BLOG_CATEGORIES from '../../utils/blogCategories';
import { blogCategoryPath } from '../../utils/path';
import styles from './BlogNavbar.module.scss';
import UntitledNavbar from './UntitledNavbar';

const BlogNavbar: React.SFC = () => (
  <UntitledNavbar name="blog" to="/blog/">
    <ul className={styles.categories}>
      {BLOG_CATEGORIES.map(({ name }) => (
        <li key={name}>
          <Link to={blogCategoryPath(name)} activeClassName={styles.active}>
            {name}
          </Link>
        </li>
      ))}
    </ul>
  </UntitledNavbar>
);

export default BlogNavbar;
