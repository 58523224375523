import queryString from 'query-string';

function respond(response: Response) {
  if (response.status >= 200 && response.status < 300) {
    return response.json().then((json) => {
      return json.data;
    });
  }

  return new Promise((_accept, reject) => {
    response.json().then((json) => {
      reject(json.errors);
    });
  });
}

export default function request<Result>(
  url: string,
  method = 'GET',
  data = {},
): Promise<Result> {
  let fetchUrl = url;
  let fetchData: typeof data | null = data;

  if (method === 'GET') {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    fetchUrl = `${process.env.SLAB_API_URL}${url}?${queryString.stringify(
      data,
      {
        arrayFormat: 'bracket',
      },
    )}`;

    fetchData = null;
  }

  return fetch(fetchUrl, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    method,
    body: fetchData && JSON.stringify(fetchData),
  }).then(respond) as Promise<Result>;
}
