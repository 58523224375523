import React from 'react';
import arrowRightRed from '../../images/blog/arrowRightRed.svg';
import howSuccessIsWrittenCover from '../../images/guides/how-success-is-written/sidebar.png';
import logo from '../../images/logo.svg';
import Link from '../ui/Link';
import styles from './SidebarCTA.module.scss';

interface SideBarCTAProps {
  category: string;
}

interface CTALinkProps {
  children: React.ReactNode;
  to: string;
  trackLabel: string;
  CTAText: string;
}

const CTALink: React.SFC<CTALinkProps> = ({
  children,
  to,
  trackLabel,
  CTAText,
}) => (
  <Link to={to} track={{ label: trackLabel }}>
    {children}
    <div className={styles.cta}>
      {CTAText}
      <img
        className={styles.arrow}
        src={arrowRightRed}
        alt=""
        role="presentation"
      />
    </div>
  </Link>
);

const SidebarCTA: React.SFC<SideBarCTAProps> = ({ category }) => {
  let sidebar = null;
  switch (category) {
    case 'Write Louder':
      sidebar = (
        <CTALink
          to="/guides/how-success-is-written/"
          trackLabel="blog-sidebar-how-sucesss-is-written-cta"
          CTAText="Download eBook"
        >
          <img
            src={howSuccessIsWrittenCover}
            className={styles.cover}
            alt="How Success is Written"
          />
        </CTALink>
      );
      break;
    // case 'Behind Slab':
    //   sidebar = (
    //     <CTALink
    //       to="/careers/"
    //       trackLabel="blog-sidebar-explore-careers-cta"
    //       CTAText="Explore careers"
    //     >
    //       <img className={styles.logo} src={logo} alt="Slab" />
    //       <img src={joinUs} className={styles.joinUs} alt="Join us." />
    //       <div className={styles.message}>
    //         Want to work on challenging problems and build the new standard in
    //         knowledge sharing?
    //       </div>
    //     </CTALink>
    //   );
    //   break;
    default:
      sidebar = (
        <CTALink to="/" trackLabel="blog-sidebar-cta" CTAText="Get started">
          <img className={styles.logo} src={logo} alt="Slab" />
          <div className={styles.message}>
            Start building a knowledge-sharing culture today.
          </div>
        </CTALink>
      );
  }

  return <div className={styles.container}>{sidebar}</div>;
};
export default SidebarCTA;
