import request from '../../utils/request';

interface ExtraParams {
  email?: string | null;
  referral?: string;
  source?: string;
  force?: boolean;
  redirectPath?: string;
}

export default function handleVendorSignUp(
  platform: string,
  { email, source, referral, force, redirectPath }: ExtraParams = {},
) {
  window.analytics.track('signup_next', {
    category: 'site',
    label: source ? `${platform}-${source}` : platform,
  });

  request<{ url: string }>(`/api/${platform}/signup`, 'GET', {
    email,
    referral,
    force,
    redirect_path: redirectPath,
  }).then(({ url }) => {
    document.location = url;
  });
}
