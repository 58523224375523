const BLOG_CATEGORIES = [
  {
    name: 'Behind Slab',
    description:
      "Learn more about how we decide what goes into Slab — and what's left out.",
  },
  {
    name: 'Write Louder',
    description:
      'Empower your team to become stronger writers so they communicate clearly and more effectively.',
  },
  {
    name: 'Work Smarter',
    description:
      'Tools and tactics to create a culture of shared knowledge to help your team fulfill their potential.',
  },
];

export default BLOG_CATEGORIES;
