import Cookies from 'js-cookie';

const YEAR = 365;
const PREFIX = 'slab-';

const HOST = process.env.SLAB_HOST;

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
const GLOBAL_DOMAIN = `.${HOST}`;

const fullKey = (key: string): string => `${PREFIX}${key}`;

type CookieKey = 'hosts' | 'blog-subscribed';

interface CookieConfig {
  expires: number;
  global?: boolean;
  json?: boolean;
  domain?: string;
}

class SlabCookie<T extends string | object> {
  private readonly configs: CookieConfig;
  private readonly key: string;

  constructor(key: CookieKey, configs: CookieConfig) {
    this.key = fullKey(key);
    this.configs = configs;
  }

  getDomain() {
    const {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line @typescript-eslint/naming-convention
      configs: { global, domain },
    } = this;

    return global ? GLOBAL_DOMAIN : domain;
  }

  set(value: T) {
    const {
      configs: { expires },
      key,
    } = this;

    Cookies.set(key, value, {
      domain: this.getDomain(),
      expires,
    });
  }

  get(): T {
    const {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line @typescript-eslint/naming-convention
      configs: { json },
      key,
    } = this;

    return Cookies[json ? 'getJSON' : 'get'](key);
  }

  remove() {
    const { key } = this;

    Cookies.remove(key, {
      domain: this.getDomain(),
    });
  }
}

export const loggedInHostsCookie = new SlabCookie<string[]>('hosts', {
  expires: YEAR,
  global: true,
  json: true,
});

export const blogSubscribedCookie = new SlabCookie('blog-subscribed', {
  expires: YEAR,
});
