import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import wrapTrack from '../../utils/wrapTrack';
import Button from '../ui/Button';
import styles from './CustomerIOForm.module.scss';

interface CustomerIOFormProps {
  onSubscribed?: () => void;
}

const STORAGE_KEY = 'customerIOForm_scrollTop';
const SITE_ID = '5321427c15239a10d4b0';
const FORM_ID = '01gpwttq7r67pkwrpapz9zvhkw';

const getTimezone = () => {
  try {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  } catch (e) {
    return '';
  }
};

const CustomerIOForm = ({ onSubscribed }: CustomerIOFormProps) => {
  const [email, setEmail] = useState('');
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [formUrl, setFormUrl] = useState('');
  const [currentUrl, setCurrentUrl] = useState('');

  useLayoutEffect(() => {
    const scrollTop = sessionStorage.getItem(STORAGE_KEY);
    if (scrollTop != null) {
      window.scrollTo(0, parseInt(scrollTop, 10));
      setIsSubscribed(true);
      onSubscribed?.();
    }

    setTimeout(() => {
      // Multiple forms could be rendered on the blog page,
      // making sure they all have a chance to read the value
      sessionStorage.removeItem(STORAGE_KEY);
    }, 10);
  }, [onSubscribed]);

  useEffect(() => {
    setCurrentUrl(
      typeof window === 'undefined' ? '' : window.document.location.toString(),
    );
  }, []);

  useEffect(() => {
    const params = new URLSearchParams({
      site_id: SITE_ID,
      form_id: FORM_ID,
      success_url: currentUrl,
    });

    setFormUrl(
      `https://customerioforms.com/forms/submit_action?${params.toString()}`,
    );
  }, [currentUrl]);

  const handleSubmit = useMemo(
    () =>
      wrapTrack({ label: 'blog-subscribe', url: currentUrl }, () => {
        sessionStorage.setItem(STORAGE_KEY, window.scrollY.toString());
      }),
    [currentUrl],
  );

  return (
    <div className={styles.formContainer}>
      {isSubscribed ? (
        <div className={styles.response}>Thank you for subscribing!</div>
      ) : (
        <form method="POST" action={formUrl} onSubmit={handleSubmit}>
          <input type="hidden" name="source" value="blog" />
          <input type="hidden" name="id" value={email} />
          <input type="hidden" name="tz" value={getTimezone()} />
          <div className="form-field form-field-email form-field-inline">
            <label className="form-control-textual form-control-textual-small">
              <div className="input-container">
                <input
                  type="email"
                  name="email"
                  placeholder="Your email address"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            </label>
          </div>
          <Button primary small type="submit">
            Subscribe
          </Button>
        </form>
      )}
    </div>
  );
};

export default CustomerIOForm;
