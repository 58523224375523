import React from 'react';
import BlogSubscriptionInsert from '../blog/BlogSubscriptionInsert';
import './BlogLayout.scss';
import BlogNavbar from './BlogNavbar';
import Layout from './Layout';
import MarketingFooter from './MarketingFooter';
import UntitledStyle from './UntitledStyle';

interface BlogLayoutProps {
  children: React.ReactNode;
}

const BlogLayout: React.SFC<BlogLayoutProps> = ({ children }) => (
  <Layout>
    <UntitledStyle>
      <BlogNavbar />
      <div id="blogPageContent">
        {children}
        <BlogSubscriptionInsert />
      </div>
    </UntitledStyle>
    <MarketingFooter />
  </Layout>
);

export default BlogLayout;
