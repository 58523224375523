import classNames from 'classnames';
import React from 'react';
import { BlogPost } from '../../types';
import styles from './BlogGradient.module.scss';

interface BlogGradientProps {
  post: BlogPost;
  featured?: boolean;
}

const BlogGradient: React.SFC<BlogGradientProps> = ({ post, featured }) => (
  <div className={styles.wrapper}>
    <div className={styles.spacer} />
    <div
      className={classNames(
        styles.container,
        post.gradient ? styles[post.gradient] : undefined,
        {
          [styles.featured]: featured,
        },
      )}
    >
      {featured && <div className={styles.label}>Featured Article</div>}
      <div className={styles.title}>{post.title}</div>
    </div>
  </div>
);

export default BlogGradient;
