import { Link } from 'gatsby';
import React from 'react';
import { blogCategoryPath } from '../../utils/path';
import styles from './BlogCategory.module.scss';

interface BlogCategoryProps {
  children: string;
}

const BlogCategory: React.SFC<BlogCategoryProps> = ({ children }) => (
  <Link to={blogCategoryPath(children)} className={styles.link}>
    {children}
  </Link>
);

export default BlogCategory;
