import { Link } from 'gatsby';
import React, { ReactNode } from 'react';
import logo from '../../images/logo.svg';
import styles from './UntitledNavbar.module.scss';

interface UntitledNavbarProps {
  name: string;
  to: string;
  children: ReactNode;
}

const UntitledNavbar: React.SFC<UntitledNavbarProps> = ({
  name,
  to,
  children,
}) => (
  <div className={styles.container}>
    <div className={styles.titleContainer}>
      <Link to="/">
        <img className={styles.logo} src={logo} alt="Slab" />
      </Link>
      <div className={styles.dot}>&middot;</div>
      <Link to={to}>
        <h4>{name}</h4>
      </Link>
    </div>
    <div>{children}</div>
  </div>
);

export default UntitledNavbar;
