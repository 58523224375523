import React, { useEffect, useState } from 'react';
import styles from './BlogTOC.module.scss';

interface BlogTOCProps {
  container?: HTMLElement;
}

interface Header {
  id: string;
  text: string;
}

const BlogTOC: React.SFC<BlogTOCProps> = ({ container }) => {
  const [headers, setHeaders] = useState([] as Header[]);
  const [activeId, setActiveId] = useState<string>();

  useEffect(() => {
    if (container) {
      const headerElements = Array.from(container.getElementsByTagName('h2'));
      const newHeaders = headerElements.map((header) => ({
        id: header.id,
        text: header.innerText,
      }));
      setHeaders(newHeaders);

      if (typeof IntersectionObserver === 'undefined') {
        return undefined;
      }

      const observer = new IntersectionObserver(
        ([entry]) => {
          if (entry.intersectionRatio === 1) {
            setActiveId(entry.target.id);
          }
        },
        { threshold: 1 },
      );

      headerElements.forEach((el) => observer.observe(el));

      return () => observer.disconnect();
    }

    return undefined;
  }, [container]);

  if (!container || !headers || headers.length === 0) {
    return null;
  }

  return (
    <ul className={styles.toc}>
      {headers.map(({ id, text }) => (
        <li className={activeId === id ? styles.active : undefined} key={id}>
          <a href={`#${id}`}>{text}</a>
        </li>
      ))}
    </ul>
  );
};

export default BlogTOC;
