import { BlogAuthor, FileEdges, IntroYamlEdges } from '../types';
import toFileMap from './toFileMap';

export interface AuthorMap {
  [slug: string]: BlogAuthor;
}

interface AuthorResponse {
  data: {
    members: { edges: IntroYamlEdges };
    pictures: { edges: FileEdges };
  };
}

const toAuthorMap: (response: AuthorResponse) => AuthorMap = ({
  data: {
    members: { edges: memberEdges },
    pictures: { edges: pictureEdges },
  },
}) => {
  const picturesMap = toFileMap(pictureEdges, 'name');

  return memberEdges.reduce(
    (
      memo,
      {
        node: {
          name,
          social,
          handle,
          fields: { slug },
        },
      },
    ) => {
      return {
        ...memo,
        [slug]: {
          name,
          pictureURL: picturesMap[slug]?.publicURL,
          childImageSharp: picturesMap[slug]?.childImageSharp,
          social,
          handle,
        },
      };
    },
    {},
  );
};

export default toAuthorMap;
