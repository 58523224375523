import React from 'react';
import { BlogPost } from '../../types';
import styles from './BlogPostSummary.module.scss';

interface BlogSummaryProps {
  post: BlogPost;
}

const getFirstElement = (html: string) => {
  const matches = html.match(/^.+<\/[^>]+>/);

  return matches?.[0];
};

const BlogPostSummary: React.SFC<BlogSummaryProps> = ({
  post: { html, summary },
}) => {
  if (summary) {
    return <div className={styles.string}>{summary}</div>;
  }

  const firstElement = getFirstElement(html);

  return firstElement ? (
    // eslint-disable-next-line react/no-danger
    <div dangerouslySetInnerHTML={{ __html: firstElement }} />
  ) : null;
};

export default BlogPostSummary;
