import React from 'react';
import classNames from 'classnames';
import GatsbyImage from 'gatsby-image';
import styles from './BlogPostHeader.module.scss';
import { BlogAuthor } from '../../types';
import Spacing from '../ui/Spacing';
import BlogCategory from './BlogCategory';

interface BlogPostHeaderProps {
  category: string;
  title: string;
  summary?: string;
  author?: BlogAuthor;
  publishDate?: string;
  editedDate?: string;
}

const BlogPostHeader: React.FunctionComponent<BlogPostHeaderProps> = ({
  category,
  title,
  summary,
  author,
  publishDate,
  editedDate,
}) => {
  const authorEl = (
    <>
      {author?.pictureURL && (
        <GatsbyImage
          className={styles.authorImage}
          fixed={author.childImageSharp.fixed}
          alt={author.name}
          fadeIn={false}
          loading="eager"
        />
      )}
      <div>
        <p className={styles.authorName}>{author?.name}</p>
        {author?.social && author?.handle && (
          <p className={styles.authorLink}>{author.handle}</p>
        )}
      </div>
    </>
  );

  return (
    <div
      className={classNames(styles.header, {
        [styles.noPadding]: !author,
      })}
    >
      <Spacing bottom={2}>
        <BlogCategory>{category}</BlogCategory>
      </Spacing>
      <h1 className={styles.title}>{title}</h1>
      {summary && <p className={styles.summary}>{summary}</p>}

      {author &&
        (author.social ? (
          <a
            href={author.social}
            target="_blank"
            rel="noreferrer"
            className={styles.metaAuthor}
          >
            {authorEl}
          </a>
        ) : (
          <div className={styles.metaAuthor}>{authorEl}</div>
        ))}

      {publishDate && (
        <div
          className={classNames(styles.metaDate, {
            [styles.fullwidth]: !author,
          })}
        >
          {editedDate ? (
            <p>
              Updated: <span>{editedDate}</span>
            </p>
          ) : (
            <p>
              Published: <span>{publishDate}</span>
            </p>
          )}
        </div>
      )}
    </div>
  );
};

export default BlogPostHeader;
