import { FormikActions } from 'formik';
import { ClientError } from 'graphql-request';
import queryString from 'query-string';
import React from 'react';
import API from '../../utils/api';
import Form, { Field } from '../ui/Form';
import UntitledSubmit from '../ui/UntitledSubmit';
import parseErrors from './parseErrors';

interface User {
  email: string;
  name?: string;
  password?: string;
}

const REGISTER_MUTATION = `
  mutation register(
    $adminEmail: String!
    $adminName: String!
    $adminPassword: String
    $referral: String
  ) {
    result: register(
      adminEmail: $adminEmail
      adminName: $adminName
      adminPassword: $adminPassword
      referral: $referral
    ) {
      redirectPath
      passwordToken
      organization {
        host
      }
    }
  }
`;

const finishSignUp = ({
  passwordToken,
  host,
  redirect,
}: {
  passwordToken: string;
  host: string;
  redirect?: string;
}) => {
  const query = queryString.stringify({
    firstLogin: true,
    redirect,
  });

  document.location.href = `https://${host}/magic-link/${passwordToken}?${query}`;
};

interface RegisterFormProps {
  email?: string;
  name?: string;
  referral?: string;
  redirect?: string;
}

const RegisterForm = ({
  email = '',
  name = '',
  referral,
  redirect,
}: RegisterFormProps) => {
  const handleSubmit = async (
    values: User,
    { setErrors }: FormikActions<User>,
  ) => {
    try {
      const {
        result: {
          redirectPath,
          passwordToken,
          organization: { host },
        },
      } = await API.request<{
        result: {
          redirectPath?: string;
          passwordToken: string;
          organization: {
            host: string;
          };
        };
      }>(REGISTER_MUTATION, {
        adminEmail: values.email,
        adminName: values.name,
        adminPassword: values.password,
        referral,
      });
      finishSignUp({
        host,
        passwordToken,
        redirect: redirect ?? redirectPath,
      });
    } catch (data) {
      setErrors(parseErrors(data as ClientError));
    }
  };

  return (
    <Form initialValues={{ email, name, password: '' }} onSubmit={handleSubmit}>
      <Field
        name="email"
        label="Email Address"
        type="email"
        required
        autoComplete="email"
        autoFocus={!email}
      />
      <Field
        name="name"
        label="Full Name"
        required
        autoComplete="name"
        autoFocus={!!email}
      />
      <Field
        name="password"
        label="Password"
        type="password"
        minLength="8"
        required
        autoComplete="new-password"
      />
      <UntitledSubmit variant="primary" block>
        Create my account
      </UntitledSubmit>
    </Form>
  );
};

export default RegisterForm;
