import { graphql, useStaticQuery } from 'gatsby';
import { fullPosts } from '../../utils/fullPosts';

const query = graphql`
  query {
    posts: allMarkdownRemark(
      filter: {
        fields: { type: { eq: "blog" } }
        frontmatter: { hide: { ne: true } }
      }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          ...BlogListPostFragment
        }
      }
    }
    ...AuthorsQueryFragment
  }
`;

const useAllPosts = () => {
  const data = useStaticQuery(query);

  return fullPosts({ data });
};

export default useAllPosts;
