import { ClientError } from 'graphql-request';
import parseValidationError from '../../utils/parseValidationError';

const parseErrors = ({ response: { errors } }: ClientError) => {
  if (!errors) {
    return {};
  }

  return errors.reduce((memo, error) => {
    const { field, message } = parseValidationError(error.message);
    if (!field) {
      return memo;
    }

    return { ...memo, [field]: message };
  }, {});
};

export default parseErrors;
